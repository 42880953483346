import { VisitorLogger } from '@wix/yoshi-flow-editor';
import { loyaltyUouCheckoutGetReward, loyaltyUouCheckoutLogin } from '@wix/bi-logger-loyalty-checkout-uou/v2';

export function createBiLogger(bi?: VisitorLogger | null) {
  const getReward = async (points: number) => {
    await bi?.report(loyaltyUouCheckoutGetReward({ points }));
  };

  const logIn = async () => {
    await bi?.report(loyaltyUouCheckoutLogin({}));
  };

  return {
    getReward,
    logIn,
  };
}
