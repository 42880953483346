export enum ElementId {
  MainStates = 'mainStates',
  LoadingState = 'loadingState',
  LoadedState = 'loadedState',
  Loader = 'loader',
  StatusText = 'statusText',
  EarnPointsText = 'earnPointsText',
  ContentStates = 'contentStates',
  NotLoggedInState = 'notLoggedInState',
  NotEnoughPointsState = 'notEnoughPointsState',
  DiscountRewardState = 'discountRewardState',
  CodeAppliedState = 'codeAppliedState',
  CodeAppliedText = 'codeAppliedText',
  LogInText = 'logInText',
  LogInButton = 'logInButton',
  PointsNeededText = 'pointsNeededText',
  PointsNeededProgressText = 'pointsNeededProgressText',
  PointsNeededProgressBar = 'pointsNeededProgressBar',
  DiscountRewardPointsInput = 'discountRewardPointsInput',
  DiscountRewardStatusText = 'discountRewardStatusText',
  DiscountRewardRedeemButton = 'discountRewardRedeemButton',
  DiscountRewardError = 'discountRewardError',
  DiscountRewardErrorText = 'discountRewardErrorText',
  ProgramIcon = 'programIcon',
}

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_CURRENCY = 'USD';
export const DISCOUNT_REWARD_COUPON_NAME = 'Loyalty - order discount';
export const ICON_BASE_URL = 'https://static.wixstatic.com';
