import { Reward as LoyaltyReward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';

import { getDiscountRewardConfig } from './getDiscountRewardConfig';

export enum NextRewardType {
  Money = 'money',
  Percentage = 'percentage',
  FreeShipping = 'freeShipping',
}

interface BaseNextRewardDetails {
  type: NextRewardType;
  costInPoints: number;
  missingUserPoints: number;
}

interface MoneyNextRewardDetails extends BaseNextRewardDetails {
  type: NextRewardType.Money;
  moneyAmountDiscount: number;
}

interface PercentageNextRewardDetails extends BaseNextRewardDetails {
  type: NextRewardType.Percentage;
  percentageDiscount: number;
}

interface FreeShippingNextRewardDetails extends BaseNextRewardDetails {
  type: NextRewardType.FreeShipping;
}

type NextRewardDetails = MoneyNextRewardDetails | PercentageNextRewardDetails | FreeShippingNextRewardDetails;

export function calculateNextRewardDetails(
  activeRewards: LoyaltyReward[],
  loyaltyAccount?: LoyaltyAccount,
): NextRewardDetails {
  const userPointsAmount = loyaltyAccount?.points?.balance ?? 0;

  // FIXME: Once coupon rewards are supported we should look at all rewards, not just restaurant discount
  const discountReward = activeRewards.find(({ type }) => type === RewardType.DISCOUNT_AMOUNT);
  const { costInPoints, discount } = getDiscountRewardConfig(discountReward, loyaltyAccount);
  const missingUserPoints = Math.max(costInPoints - userPointsAmount, 0);

  return {
    type: NextRewardType.Money,
    costInPoints,
    missingUserPoints,
    moneyAmountDiscount: discount,
  };
}
