import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { applyDiscountToCheckout } from '@wix/ambassador-loyalty-checkoutexchange-v1-loyalty-checkout-discount/http';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';

interface ApplyDiscountRewardParams {
  flowAPI: PlatformControllerFlowAPI;
  checkoutId: string;
  discountReward: LoyaltyReward;
  pointsToSpend: number;
}

export async function applyDiscountReward({
  flowAPI,
  checkoutId,
  discountReward,
  pointsToSpend,
}: ApplyDiscountRewardParams): Promise<void> {
  const { httpClient } = flowAPI;

  await httpClient.request(
    applyDiscountToCheckout({
      checkoutId,
      reward: {
        id: discountReward?.id!,
        revision: discountReward?.revision!,
        pointsToSpend,
      },
    }),
  );
}
